import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _76804346 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _7546e4a6 = () => interopDefault(import('../pages/case/index.vue' /* webpackChunkName: "pages/case/index" */))
const _ff84a7c2 = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _0fd7575a = () => interopDefault(import('../pages/privacyPolicy/index.vue' /* webpackChunkName: "pages/privacyPolicy/index" */))
const _683b2746 = () => interopDefault(import('../pages/mobile/about/index.vue' /* webpackChunkName: "pages/mobile/about/index" */))
const _b1d53494 = () => interopDefault(import('../pages/mobile/case/index.vue' /* webpackChunkName: "pages/mobile/case/index" */))
const _1363766a = () => interopDefault(import('../pages/mobile/privacyPolicy/index.vue' /* webpackChunkName: "pages/mobile/privacyPolicy/index" */))
const _f5e544c4 = () => interopDefault(import('../pages/mobile/case/_id.vue' /* webpackChunkName: "pages/mobile/case/_id" */))
const _2c1c1755 = () => interopDefault(import('../pages/case/_id.vue' /* webpackChunkName: "pages/case/_id" */))
const _508d17ac = () => interopDefault(import('../pages/mobile/_.vue' /* webpackChunkName: "pages/mobile/_" */))
const _101d25c8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e5146456 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _76804346,
    name: "about"
  }, {
    path: "/case",
    component: _7546e4a6,
    name: "case"
  }, {
    path: "/mobile",
    component: _ff84a7c2,
    name: "mobile"
  }, {
    path: "/privacyPolicy",
    component: _0fd7575a,
    name: "privacyPolicy"
  }, {
    path: "/mobile/about",
    component: _683b2746,
    name: "mobile-about"
  }, {
    path: "/mobile/case",
    component: _b1d53494,
    name: "mobile-case"
  }, {
    path: "/mobile/privacyPolicy",
    component: _1363766a,
    name: "mobile-privacyPolicy"
  }, {
    path: "/mobile/case/:id",
    component: _f5e544c4,
    name: "mobile-case-id"
  }, {
    path: "/case/:id",
    component: _2c1c1755,
    name: "case-id"
  }, {
    path: "/mobile/*",
    component: _508d17ac,
    name: "mobile-all"
  }, {
    path: "/",
    component: _101d25c8,
    name: "index"
  }, {
    path: "/*",
    component: _e5146456,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
