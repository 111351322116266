import api from './api'
import queryString from 'query-string'
class Base {
  constructor () {
    this.baseURL = ''
  }

  /**
   * Get请求
   * @param { String } url
   * @param { Object } params
   */
  sendGet (url, params) {
    return api.get(url, {
      baseURL: this.baseURL,
      params
    }).then(response => {
      return response
    }).catch((error) => {
      return { ok: false, error: error }
    })
  }

  /**
   * Post 请求
   * @param { String } url
   * @param { Object} data
   */
  sendPost (url, data) {
    return api.post(url, data, {
      baseURL: this.baseURL
    }).then(response => {
      return response
    }).catch((error) => {
      return {
        ok: false,
        error: error
      }
    })
  }

  sendPostForm (url, data) {
    return api.post(url, queryString.stringify(data), {
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      return response
    }).catch((error) => {
      return {
        ok: false,
        error: error
      }
    })
  }

  uploadFile (url, data) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(url, data, config).then(response => {
      return response
    }).catch(() => {
      return {
        ok: false
      }
    })
  }
}
export default Base
